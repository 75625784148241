// in src/App.js
import React from 'react';
import { Admin, Resource } from 'react-admin';
import { dataProvider, authProvider } from './config';

import { UserList, UserShow, UserEdit } from './views/users';
import { ProfileList, ProfileShow, ProfileEdit } from './views/profiles';
import { UnitList, UnitShow, UnitEdit } from './views/units';
import { AccessList, AccessCreate, AccessEdit } from './views/accesses';
import { ProcedureList, ProcedureShow, ProcedureCreate, ProcedureEdit, ExamList } from './views/procedures';
import { SaleList, SaleShow, SaleEdit, SaleItemList } from './views/sales';
import { PartnerList, PartnerShow } from './views/partners';
import { FeedbackList, FeedbackEdit } from './views/feedbacks';
import Dashboard from './views/dashboard';

import {
  Person as UserIcon,
  AccountCircle as ProfileIcon,
  AssignmentInd as AccessIcon,
  Business as UnitIcon,
  ShoppingCart as SaleIcon,
  SupervisorAccount as PartnerIcon,
  Chat as FeedbackIcon
} from '@material-ui/icons';

import portugueseMessages from 'ra-language-portuguese';

const messages = {
  'pt-BR': {
    ...portugueseMessages,
    Unauthorized: 'Sem autorização',
    'Unprocessable Entity': 'Erro de validação',
    resources: {
      users: {
        name: 'Usuário |||| Usuários',
        fields: {
          username: 'Usuário',
          active: 'Ativo',
          password: 'Senha',
          club: 'Clube de benefícios',
          'accessTokens.length': 'Acessos',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      units: {
        name: 'Unidade |||| Unidades',
        fields: {
          name: 'Nome',
          socialName: 'Razão social',
          responsible: 'Responsável',
          ownerId: 'Proprietário',
          active: 'Ativa',
          credit: 'Crédito (R$)',
          costPercent: 'Custo percentual (%)',
          costAmount: 'Custo fixo (R$)',
          monthlyPayment: 'Mensalidade (R$)',
          commercialId: 'Comercial',
          paymentSellerId: 'Grupo econômico',
          createdAt: 'Criado em',
          acceptedTerms: 'Termos',
          updatedAt: 'Atualizado em'
        },
      },
      accesses: {
        name: 'Acesso |||| Acessos',
        fields: {
          unitId: 'Unidade',
          userId: 'Usuário',
          role: 'Papel',
          notify: 'Recebe notificação',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      procedures: {
        name: 'Procedimento |||| Procedimentos',
        fields: {
          name: 'Nome',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      sales: {
        name: 'Venda |||| Vendas',
        fields: {
          authorId: 'Autor',
          salesmanId: 'Parceiro',
          practiceId: 'Local de atendimento',
          value: 'Valor',
          valueWithDiscount: 'Preço',
          discount: 'Desconto',
          commission: 'Comissão',
          name: 'Paciente',
          cpf: 'CPF',
          email: 'E-mail',
          cellphone: 'Celular',
          birthDate: 'Data de nascimento',
          description: 'Observações',
          "externalPayment.value": 'Valor dinheiro',
          "externalPayment.Splits": 'Splits de devolução',
          "payment.value": 'Valor do boleto',
          "payment.fee": 'Taxa do boleto',
          "payment.PaymentStatuses": 'Histórico',
          "payment.Splits": 'Splits de pagamento',
          paidNema: 'Recebido do vendedor em',
          paidPractice: 'Repassado para clínica em',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      agreements: {
        name: 'Acordo |||| Acordos',
        fields: {
          name: 'Nome',
          unitId: 'Local do atendimento',
          finishedAt: 'Finalizado em',
          finishedBy: 'Finalizado por',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      exams: {
        name: 'Exame |||| Exames',
        fields: {
          name: 'Nome',
          value: 'Valor',
          procedures: 'Procedimentos',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      deals: {
        fields: {
          agreementId: 'Acordo',
          discount: 'Desconto',
          commission: 'Comissão',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      sellers: {
        fields: {
          agreementId: 'Acordo',
          confirmatedAt: 'Confirmado em',
          confirmatedBy: 'Confirmado por',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      profiles: {
        name: 'Perfil |||| Perfis',
        fields: {
          firstName: 'Nome',
          lastName: 'Sobrenome',
          fullName: 'Nome',
          cellphone: 'Celular',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      addresses: {
        fields: {
          street: 'Endereço',
          number: 'Número',
          complement: 'Complemento',
          neighborhood: 'Bairro',
          city: 'Cidade',
          state: 'Estado',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
      saleItems: {
        name: 'Item da venda |||| Itens da venda',
        fields: {
          doneAt: 'Realizado em',
          doneBy: 'Realizado por',
          examId: 'Exame',
          amount: 'Quantidade',
          value: 'Valor',
          valueWithDiscount: 'Preço',
          discount: 'Desconto',
          commission: 'Comissão',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'

        }
      },
      feedbacks: {
        name: 'Feedback |||| Feedbacks',
        fields: {
          content: 'Conteúdo',
          userId: 'Usuário',
          unitId: 'Clínica',
          createdAt: 'Criado em',
          updatedAt: 'Atualizado em'
        }
      },
    }
  },
};
const i18nProvider = locale => messages[locale];

const App = () => (
  <Admin
    dashboard={Dashboard}
    locale="pt-BR"
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource
      name="users"
      icon={UserIcon}
      list={UserList}
      show={UserShow}
      edit={UserEdit}
    />
    <Resource
      name="profiles"
      icon={ProfileIcon}
      list={ProfileList}
      show={ProfileShow}
      edit={ProfileEdit}
    />
    <Resource
      name="accesses"
      icon={AccessIcon}
      list={AccessList}
      create={AccessCreate}
      edit={AccessEdit}
    />
    <Resource
      name="units"
      icon={UnitIcon}
      list={UnitList}
      show={UnitShow}
      edit={UnitEdit}
    />
    <Resource
      name="agreements"
      icon={PartnerIcon}
      list={PartnerList}
      show={PartnerShow}
    />
    <Resource
      name="sales"
      icon={SaleIcon}
      list={SaleList}
      show={SaleShow}
      edit={SaleEdit}
    />
    <Resource
      name="saleItems"
      list={SaleItemList}
    />
    <Resource
      name="exams"
      list={ExamList}
    />
    <Resource
      name="procedures"
      list={ProcedureList}
      show={ProcedureShow}
      create={ProcedureCreate}
      edit={ProcedureEdit}
    />
    <Resource
      name="feedbacks"
      icon={FeedbackIcon}
      list={FeedbackList}
      edit={FeedbackEdit}
    />

    <Resource name="paymentSellers/list" />
    <Resource name="paymentTypes" />
    <Resource name="deals" />
    <Resource name="sellers" />
    <Resource name="disableds" />
    <Resource name="phones" />
    <Resource name="addresses" />
  </Admin>
);

export default App;
