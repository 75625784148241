import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  NumberField,
  EditButton
} from 'react-admin';

export default function UnitList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="cnpj" />
        <NumberField source="credit" />
        <NumberField source="costPercent" />
        <ReferenceField source="paymentSellerId" reference="paymentSellers/list">
          <TextField source="businessName" />
        </ReferenceField>
        <ReferenceField source="ownerId" reference="users" linkType="show">
          <TextField source="email" />
        </ReferenceField>
        <BooleanField source="active" />
        <DateField source="acceptedTerms" />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
}