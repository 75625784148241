import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  DateInput,
  AutocompleteInput,
  ShowButton,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  BooleanInput,
  FunctionField,
  SelectField,
} from 'react-admin';
import { SaleStatuses, PaymentTypes, filterLike } from '../../data';
import XLSX from 'xlsx';

const STATUS = {
  'created': 'Criado',
  'pending': 'Pendente',
  'paid': 'Pago'
};

const getStatus = sale => {
  const payment = sale.payment;
  if (payment) {
    return `${payment.type} - ${STATUS[payment.status] || payment.status}`;
  }
  if (sale.externalPayment) {
    return 'Dinheiro';
  }
  return '-';
}

const getValue = prop => sale => {
  const value = sale[prop];

  if (value) {
    return `R$ ${(value/100).toFixed(2)}`;
  }
};

const exporter = sales => {
  const sheet = [{
    date: 'Data',
    patient: 'Paciente',
    price: 'Valor pago',
    commission: 'Valor indicação',
    payback: 'Valor repasse',
    practiceId: 'Executora',
    salesmanId: 'Indicadora',
    type: 'Tipo',
  }];
  let profit = 0, payback = 0;
  const procedures = [{
    saleId: 'Id da venda',
    unitId: 'Id da unidade',
    procedureId: 'Id do procedimento',
    procedureName: 'Nome do procedimento'
  }];
  sales.forEach(sale => {
    const v = sale.valueWithDiscount - sale.commission;
    profit += sale.commission;
    payback += v;
    if (sale.saleItems) {
      sale.saleItems.forEach(si => {
        si.deal.exam.procedures.forEach(p => {
          procedures.push({
            saleId: sale.id,
            unitId: sale.practiceId,
            procedureId: p.id,
            procedureName: p.name
          });
        });
      });
    }
    sheet.push({
      date: { t: "d", v: new Date(sale.createdAt), z: "d-mmm" },
      patient: sale.name,
      price: { t: "n", v: sale.valueWithDiscount/100, z: "\"R$\" #0.00" },
      commission: { t: "n", v: sale.commission/100, z: "\"R$\" #0.00" },
      payback: { t: "n", v: v/100, z: "\"R$\" #0.00" },
      practiceId: sale.practiceId,
      salesmanId: sale.salesmanId,
      type: sale.payment ? sale.payment.type : sale.externalPayment ? 'dinheiro' : ''
    });
  });
  sheet.push({
    date: 'Total',
    patient: '',
    price: '',
    commission: { t: "n", v: profit/100, z: "\"R$\" #0.00" },
    payback: { t: "n", v: payback/100, z: "\"R$\" #0.00" }
  });
  const w_sales = XLSX.utils.json_to_sheet(sales.map(s => {
    const _s = { ...s };
    if (s.payment) {
      _s.paymentStatus = s.payment.status;
    }
    delete _s.payment;
    delete _s.externalPayment;
    return _s;
  }));
  const w_billing = XLSX.utils.json_to_sheet(sheet, { skipHeader: true });
  w_billing['!rows'] = [{ hpx: 25 }];
  w_billing['!cols'] = [{ wch: 10 }, { wch: 40 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
  const r = sales.length + 1;
  w_billing['!merges'] = [{s: {r, c: 0}, e: {r, c: 2}}];
  const w_procedures = XLSX.utils.json_to_sheet(procedures, { skipHeader: true });
  w_procedures['!cols'] = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 80 }];

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, w_sales, 'dados');
  XLSX.utils.book_append_sheet(wb, w_billing, 'fatura');
  XLSX.utils.book_append_sheet(wb, w_procedures, 'procedimentos');
  XLSX.writeFile(wb, 'vendas_nema.xlsx');
};

const SaleFilter = props => (
  <Filter {...props}>
    <TextInput label="Paciente" source="[name][like]" alwaysOn />
    <TextInput label="CPF" source="[cpf][like]" />
    <SelectInput label="Status" source="status" choices={SaleStatuses} />
    <ReferenceInput
      label="Local de atendimento"
      source="practiceId"
      reference="units"
      alwaysOn
      perPage={10}
      filterToQuery={filterLike('name')}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Parceiro"
      source="salesmanId"
      reference="units"
      alwaysOn
      perPage={10}
      filterToQuery={filterLike('name')}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <DateInput label="De" source="[createdAt][gte]" alwaysOn />
    <DateInput label="Até" source="[createdAt][lte]" alwaysOn />
    <SelectInput label="Pagamento" source="paymentTypeId" choices={PaymentTypes} alwaysOn />
    <BooleanInput label="Dinheiro" source="[externalPaymentId][notNull]" alwaysOn />
  </Filter>
);

export default function SaleList(props) {
  return (
    <List {...props} filters={<SaleFilter />} filter={{ include: { saleItems: { deal: { exam: 'procedures' } } } }} exporter={exporter}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField source="practiceId" reference="units" linkType="show">
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="status" choices={SaleStatuses} />
        <FunctionField label="Pagamento" render={getStatus} />
        <FunctionField label="Valor" render={getValue('value')} />
        <FunctionField label="Preço" render={getValue('valueWithDiscount')} />
        <FunctionField label="Repasse" render={getValue('commission')} />
        <FunctionField label="Nema" render={getValue('nema')} />
        <TextField source="name" />
        <DateField source="createdAt" />
        <ShowButton />
      </Datagrid>
    </List>
  );
}