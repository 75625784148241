import React from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  ReferenceField,
  TextField,
  DisabledInput
} from 'react-admin';
import { FeedbackStatuses } from '../../data';

export default function AccessEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField label="Autor" source="userId" reference="users">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="Unidade" source="unitId" reference="units">
          <TextField source="name" />
        </ReferenceField>
        <DisabledInput source="content" />
        <SelectInput source="status" choices={FeedbackStatuses} />
      </SimpleForm>
    </Edit>
  );
}