import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { itemUndone } from './actions';

const UndoneButton = ({ itemUndone, record }) => (
  <Button onClick={() => itemUndone(record.id, record)}>
    Desfazer
  </Button>
)

UndoneButton.propTypes = {
  itemUndone: PropTypes.func.isRequired,
  record: PropTypes.object,
};

export default connect(undefined, { itemUndone })(UndoneButton);