import React from 'react';
import {
  ReferenceField,
  DateField,
  TextField
} from 'react-admin';

export const ConditionalUser = ({ record, source, fieldSource = "email", ...props }) => (
  record && record[source] ? (
    <ReferenceField record={record} source={source} reference="users" linkType="show" {...props}>
      <TextField source={fieldSource} />
    </ReferenceField>
  ) : null
);

export const ConditionalField = ({ record, source, fieldSource, ...props }) => (
  record && record[source] ? (
    <ReferenceField record={record} source={source} {...props}>
      <TextField source={fieldSource} />
    </ReferenceField>
  ) : null
);

export const Disabled = ({ record, reason, ...props }) => (
  record && record.disabledId ? (
    <ReferenceField record={record} source="disabledId" reference="disableds" linkType={false} {...props}>
      {reason ? <TextField source="reason" /> : <DateField source="createdAt" />}
    </ReferenceField>
  ) : null
);