import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SelectField,
  DeleteButton,
  BooleanField
} from 'react-admin';
import { Roles } from '../../data';

export default function AccessList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <ReferenceField source="userId" reference="users" linkType="show">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="unitId" reference="units" linkType="show">
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="role" choices={Roles} />
        <BooleanField source="notify" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
}