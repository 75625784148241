import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  ArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
  Filter,
  TextInput,
  NullableBooleanInput,
  BooleanInput,
} from 'react-admin';
import { Disabled } from '../conditionals';

const ExamFilter = props => (
  <Filter {...props}>
    <TextInput label="Nome" source="[name][like]" alwaysOn />
    <NullableBooleanInput label="Habilidado" source="enabled" alwaysOn />
    <BooleanInput label="Deletado" source="[disabledId][notNull]" alwaysOn />
  </Filter>
);

const getValue = prop => sale => {
  const value = sale[prop];

  if (value) {
    return `R$ ${(value/100).toFixed(2)}`;
  }
};

export default function UnitList(props) {
  return (
    <List {...props} filter={{ include: 'procedures' }} filters={<ExamFilter />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <FunctionField label="Valor" render={getValue('value')} />
        <ReferenceField source="unitId" reference="units">
          <TextField source="name" />
        </ReferenceField>
        <ArrayField source="procedures">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <Disabled label="Motivo" reason />
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
}