import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';

export default function ProcedureShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="tuss" />
      </SimpleShowLayout>
    </Show>
  );
}