import React from 'react';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput
} from 'react-admin';

export default function ProcedureEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <DisabledInput source="id" />
        <TextInput source="name" />
        <TextInput source="tuss" />
      </SimpleForm>
    </Edit>
  );
}