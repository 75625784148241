import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { submit, isSubmitting } from 'redux-form';
import {
  fetchEnd,
  fetchStart,
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  CREATE,
  refreshView,
  BooleanInput
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import { withStyles } from '@material-ui/core/styles';

import { dataProvider } from '../../config';
import { Roles, filterLike } from '../../data';

const styles = {
  button: {
    marginTop: '1em',
  },
};

class AccessCreateModal extends Component {
  state = {
    error: false,
    showDialog: false,
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleSaveClick = () => {
    this.props.submit('acesses-quick-create');
  };

  handleSubmit = data => {
    const {
      fetchStart,
      fetchEnd,
      showNotification,
      refreshView
    } = this.props;

    fetchStart();
    dataProvider(CREATE, 'accesses', { data })
      .then(() => {
        this.setState({ showDialog: false });
      })
      .catch(error => {
        showNotification(error.message, 'warning');
      })
      .finally(() => {
        refreshView();
        fetchEnd();
      });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting, classes, record, mode } = this.props;

    return (
      <Fragment>
        <Button
          className={classes.button}
          variant="raised"
          label="Adicionar acesso"
          onClick={this.handleClick}
        >
          <AssignmentInd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Adicionar acesso"
        >
          <DialogTitle>Adicionar acesso para {record.email}</DialogTitle>
          <DialogContent>
            <SimpleForm
              form="acesses-quick-create"
              resource="accesses"
              onSubmit={this.handleSubmit}
              toolbar={null}
              defaultValue={{ [mode]: record.id }}
            >
              <ReferenceInput
                source="userId"
                reference="users"
                perPage={10}
                filterToQuery={filterLike('email')}
                disabled={mode === 'userId'}
              >
                <AutocompleteInput optionText="email" />
              </ReferenceInput>
              <ReferenceInput
                source="unitId"
                reference="units"
                perPage={10}
                filterToQuery={filterLike('name')}
                disabled={mode === 'unitId'}
              >
                <AutocompleteInput optionText="name" />
              </ReferenceInput>
              <SelectInput source="role" choices={Roles} />
              <BooleanInput source="notify" />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick} >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
};

const mapStateToProps = state => ({
  isSubmitting: isSubmitting('acesses-quick-create')(state)
});

const mapDispatchToProps = {
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  refreshView
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AccessCreateModal));
