import React from 'react';
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  number,
  Toolbar,
  SaveButton
} from 'react-admin';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default function ProfileEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <DisabledInput source="id" />
        <TextInput source="firstName" />
        <TextInput source="lastName" />
        <TextInput source="cellphone" validate={[number()]} />
        <TextInput source="cpf" validate={[number()]} />
      </SimpleForm>
    </Edit>
  );
}