import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ShowButton
} from 'react-admin';
import { ConditionalUser, Disabled } from '../conditionals';

export default function PartnerList(props) {
  return (
    <List {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="finishedAt" />
        <ConditionalUser source="finishedBy" />
        <Disabled />
        <ShowButton />
      </Datagrid>
    </List>
  );
}