import { queryParameters, fetchJson } from './fetch';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
} from './types';
import moment from 'moment';

export * from './authClient';

const sequelize = ['paymentsellers/list', 'customers', 'paymenttypes'];
const ignore = ['accessTokens', 'externalPayment', 'payment'];
const convertToDate = ['paidNema', 'paidPractice'];

/**
 * Maps admin-on-rest queries to a loopback powered REST API
 *
 * @see https://github.com/strongloop/loopback
 * @example
 * GET_LIST     => GET http://my.api.url/posts?filter[sort]="title ASC"&filter[skip]=0&filter[limit]=20
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter[where][or]=[{id:123},{id:456}]
 * UPDATE       => PATCH http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertRESTRequestToHTTP = (type, resource, params) => {
    resource = resource.toLowerCase();
    let url = '';
    const options = {};
    const isSequelize = sequelize.includes(resource);
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const { include, ...where } = params.filter || {};
        const query = { where, include };
        if (query.where) {
          Object.keys(query.where)
            .forEach(key => {
              const _where = query.where[key];
              const _like = _where.like;
              if (_like) {
                query.where[key] = { like: `%${_like}%` };
              } else if (_where.gte && _where.lte) {
                query.where[key] = {
                  between: [
                    moment(_where.gte).startOf('day').toDate(),
                    moment(_where.lte).endOf('day').toDate(),
                  ]
                };
              } else if (_where.gte) {
                query.where[key] = { gte: moment(_where.gte).startOf('day').toDate() };
              } else if (_where.lte) {
                query.where[key] = { lte: moment(_where.lte).endOf('day').toDate() };
              } else if (_where.notNull !== undefined) {
                if (_where.notNull) {
                  query.where[key] = { neq: null };
                } else {
                  delete query.where[key];
                }
              } else if (_where.isNull !== undefined) {
                if (_where.isNull) {
                  query.where[key] = null;
                } else {
                  delete query.where[key];
                }
              }
            })
        }
        if (field) query['order'] = isSequelize ? [[field, order]] : [field + ' ' + order];
        if (perPage > 0) {
          query['limit'] = perPage;
          if (page >= 0) {
            query['skip'] = (page - 1) * perPage;
          }
        }
        // console.log('GET_LIST', type, query, resource, params);
        url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        break;
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`;
        break;
      case GET_MANY: {
        let inOp = 'inq';
        if (isSequelize) {
          inOp = 'in';
        }
        const query = {
          'where': { 'id': { [inOp]: params.ids }}
        };
        url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        break;
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = { ...params.filter };
        if (!query.where) {
          query['where'] = {};
        }
        query['where'][params.target] = params.id;
        if (field) query['order'] = isSequelize ? [[field, order]] : [field + ' ' + order];
        if (perPage > 0) {
          query['limit'] = perPage;
          if (page >= 0) {
            query['skip'] = (page - 1) * perPage;
          }
        }
        // console.log('GET_MANY_REFERENCE', query);
        url = `${apiUrl}/${resource}?${queryParameters({ filter: JSON.stringify(query) })}`;
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'PATCH';
        const body = Object.keys(params.data).reduce((r, k) => {
          if (!ignore.includes(k)) {
            let value = params.data[k];
            if (value && convertToDate.includes(k)) {
              const m = moment(value, 'DD/MM/YYYY HH:mm');
              value = m.isValid() ? m.toDate() : null;
            }
            r[k] = value;
          }
          return r;
        }, {});
        options.body = JSON.stringify(body);
        break;
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = 'POST';
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = 'DELETE';
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The REST request params, depending on the type
   * @returns {Object} REST response
   */
  const convertHTTPResponseToREST = (response, type, resource, params) => {
    const { headers, json } = response;
    if (json.meta) {
      return { data: json.data, total: json.meta.totalCount };
    }
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('x-total-count')) {
          throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
        }
        return {
          data: json,
          total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
        };
      case CREATE:
        return { data: { ...params.data, id: json.id } };
      default:
        return { data: json };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a REST response
   */
  return (type, resource, params) => {
    const { url, options } = convertRESTRequestToHTTP(type, resource, params);
    return httpClient(url, options)
      .then(response => convertHTTPResponseToREST(response, type, resource, params));
  };
};
