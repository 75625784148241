import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  ReferenceField,
  NumberField,
  ReferenceManyField,
  DateField,
  Datagrid
} from 'react-admin';
import { ConditionalUser, Disabled } from '../conditionals';

export default function PartnerShow(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informações">
          <TextField source="name" />
          <DateField source="finishedAt" />
          <ConditionalUser source="finishedBy" />
        </Tab>
        <Tab label="Exames">
          <ReferenceManyField reference="deals" target="agreementId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="examId" reference="exams" linkType={false}>
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="discount" />
              <NumberField source="commission" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Vendedores">
          <ReferenceManyField reference="sellers" target="agreementId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="unitId" reference="units" linkType={false}>
                <TextField source="name" />
              </ReferenceField>
              <DateField source="confirmatedAt" />
              <ConditionalUser source="confirmatedBy" />
              <Disabled label="Desabilitado em" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}