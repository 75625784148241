import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  RichTextField,
  DateField,
  ReferenceManyField,
  ReferenceField,
  SingleFieldList,
  SelectField,
  EditButton,
  Filter,
  SelectInput,
  BooleanInput
} from 'react-admin';
import { FeedbackStatuses } from '../../data';

const FeedbackFilter = props => (
  <Filter {...props}>
    <SelectInput label="Status" source="status" choices={FeedbackStatuses} alwaysOn />
    <BooleanInput label="Sem status" source="[status][isNull]" alwaysOn />
  </Filter>
);

export default function FeedbackList(props) {
  return (
    <List {...props} filters={<FeedbackFilter />}>
      <Datagrid>
        <TextField source="id" />
        <RichTextField source="content" />
        <ReferenceManyField label="Nome" target="userId" reference="profiles">
          <SingleFieldList>
            <TextField source="fullName" />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceField source="unitId" reference="units" linkType="show">
          <TextField source="name" />
        </ReferenceField>
        <SelectField source="status" choices={FeedbackStatuses} />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
}