import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  EditButton,
  ReferenceManyField,
  SingleFieldList,
  Filter,
  TextInput,
  NullableBooleanInput,
  FunctionField,
  NumberField,
} from 'react-admin';
import moment from 'moment';

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="E-mail" source="[email][like]" alwaysOn />
    <TextInput label="Usuário" source="[username][like]" alwaysOn />
    <NullableBooleanInput label="Clube" source="club" alwaysOn />
  </Filter>
);

const filter = {
  include: {
    relation: 'accessTokens',
    scope: {
      fields: ['created']
    }
  }
};

const getLastAccess = user => {
  const dates = (user.accessTokens || []).map(at => at.created);
  dates.sort().reverse();
  if (!dates.length) {
    return null;
  }
  return moment(dates[0]).format('DD/MM/YYYY HH:mm');
};

export default function UserList(props) {
  return (
    <List {...props} filters={<UserFilter />} filter={filter}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="username" />
        <TextField source="email" />
        <ReferenceManyField label="Nome" target="userId" reference="profiles">
          <SingleFieldList>
            <TextField source="fullName" />
          </SingleFieldList>
        </ReferenceManyField>
        <NumberField source="accessTokens.length" />
        <FunctionField label="Último acesso" render={getLastAccess} />
        <BooleanField source="active" />
        <BooleanField source="club" />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
}