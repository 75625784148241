import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  ReferenceField,
  ReferenceManyField,
  DateField,
  NumberField,
  RichTextField,
  Datagrid,
  EmailField,
  ArrayField,
  SelectField,
  FunctionField,
} from 'react-admin';
import { PaymentStatus, SplitTypes } from '../../data';
import UndoneButton from './UndoneButton';
import { ConditionalUser } from '../conditionals';
import get from 'lodash/get';

const getValue = prop => sale => {
  const value = get(sale, prop);

  if (value) {
    return `R$ ${(value/100).toFixed(2)}`;
  }
};

export default function SaleShow(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informações">
          <TextField source="id" />
          <ReferenceField source="authorId" reference="users" linkType="show">
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField source="salesmanId" reference="units" linkType="show">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="practiceId" reference="units" linkType="show">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="name" />
          <TextField source="cpf" />
          <TextField source="cellphone" />
          <EmailField source="email" />
          <DateField source="birthDate" />
          <RichTextField source="description" />
        </Tab>
        <Tab label="Financeiro">
          <FunctionField label="Valor dinheiro" render={getValue('externalPayment.value')} />
          <ArrayField source="externalPayment.Splits">
            <Datagrid>
              <SelectField source="type" label="Para" choices={SplitTypes} />
              <FunctionField label="Valor" render={getValue('amount')} />
            </Datagrid>
          </ArrayField>
          <FunctionField label="Valor do boleto" render={getValue('payment.value')} />
          <FunctionField label="Taxa do boleto" render={getValue('payment.fee')} />
          <ArrayField source="payment.Splits">
            <Datagrid>
              <SelectField source="type" label="Para" choices={SplitTypes} />
              <FunctionField label="Valor" render={getValue('amount')} />
            </Datagrid>
          </ArrayField>
          <ArrayField source="payment.PaymentStatuses">
            <Datagrid>
              <SelectField source="type" label="Tipo" choices={PaymentStatus} />
              <DateField source="createdAt" label="Quando" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Exames">
          <ReferenceManyField reference="saleItems" target="saleId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="examId" reference="exams" linkType={false}>
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="amount" />
              <FunctionField label="Valor" render={getValue('value')} />
              <FunctionField label="Desconto" render={getValue('discount')} />
              <FunctionField label="Preço" render={getValue('valueWithDiscount')} />
              <FunctionField label="Repasse" render={getValue('commission')} />
              <DateField source="doneAt" />
              <ConditionalUser source="doneBy" />
              <UndoneButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}