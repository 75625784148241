import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  NumberField,
  FunctionField,
} from 'react-admin';
import { ConditionalUser } from '../conditionals';

const getValue = prop => sale => {
  const value = sale[prop];

  if (value) {
    return `R$ ${(value/100).toFixed(2)}`;
  }
};

export default function SaleItemList(props) {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="saleId" />
        <ReferenceField source="examId" reference="exams" linkType={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="amount" />
        <FunctionField label="Preço" render={getValue('valueWithDiscount')} />
        <FunctionField label="Desconto" render={getValue('discount')} />
        <FunctionField label="Repasse" render={getValue('commission')} />
        <DateField source="doneAt" />
        <ConditionalUser source="doneBy" />
      </Datagrid>
    </List>
  );
}