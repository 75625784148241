export const SaleStatuses = [
  { id: 'created', name: 'Criada' },
  { id: 'pending', name: 'Pendente' },
  { id: 'scheduled', name: 'Agendada' },
  { id: 'executed', name: 'Realizada' },
  { id: 'cancelled', name: 'Cancelada' }
];

export const PaymentTypes = [
  { id: 1, name: 'Boleto' },
  { id: 2, name: 'Débito' },
  { id: 3, name: 'Crédito' },
  { id: 4, name: 'Link de pagamento' }
];

export const FeedbackStatuses = [
  { id: 'resolved', name: 'Resolvido' },
  { id: 'pending', name: 'Pendente' },
];

export const Roles = [
  { id: 'admin', name: 'Administrador' },
  { id: 'staff', name: 'Atendimento' },
];

export const PaymentStatus = [
  { id: 'created', name: 'Criado' },
  { id: 'waiting_payment', name: 'Aguardando pagamento' },
  { id: 'paid', name: 'Pago' },
];

export const SplitTypes = [
  { id: 'Salesman', name: 'Vendedor' },
  { id: 'Nema', name: 'Nema' },
  { id: 'Practice', name: 'Executor' },
];

export const AccountTypes = [
  { id: 'conta_corrente', name: 'Conta corrente' },
  { id: 'conta_poupanca', name: 'Conta poupança' },
  { id: 'conta_corrente_conjunta', name: 'Conta corrente conjunta' },
  { id: 'conta_poupanca_conjunta', name: 'Conta poupança conjunta' },
];

export const PRICE_FORMAT = {
  style: 'currency',
  currency: 'BRL',
};

export const filterLike = prop => query => ({
  [prop]: { like: `%${query}%` }
});