import React from 'react';
import {
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  DateField,
  Datagrid,
  SelectField,
  EditButton
} from 'react-admin';
import { Roles } from '../../data';

export default function UserShow(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informações">
          <TextField source="id" />
          <TextField source="username" />
          <TextField source="email" type="email" />
          <ReferenceManyField reference="profiles" target="userId" addLabel={false}>
            <Datagrid>
              <TextField source="firstName" />
              <TextField source="lastName" />
              <TextField source="cellphone" />
              <TextField source="cpf" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <BooleanField source="active" />
        </Tab>
        <Tab label="Acessos">
          <ReferenceManyField reference="accesses" target="userId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="unitId" reference="units" linkType="show">
                <TextField source="name" />
              </ReferenceField>
              <SelectField source="role" choices={Roles} />
              <BooleanField source="notify" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Unidades">
          <ReferenceManyField reference="units" target="ownerId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <TextField source="cnpj" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Comercial">
          <ReferenceManyField reference="units" target="commercialId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <TextField source="cnpj" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}