import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';
import { Roles, filterLike } from '../../data';

export default function AccessEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceInput
          source="userId"
          reference="users"
          perPage={10}
          filterToQuery={filterLike('email')}
        >
          <AutocompleteInput optionText="email" />
        </ReferenceInput>
        <ReferenceInput
          source="unitId"
          reference="units"
          perPage={10}
          filterToQuery={filterLike('name')}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="role" choices={Roles} />
        <BooleanInput source="notify" />
      </SimpleForm>
    </Edit>
  );
}