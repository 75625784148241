import React, { Component } from 'react';
import { ResponsiveCalendarCanvas } from '@nivo/calendar';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import { GET_LIST, showNotification } from 'react-admin';
import { dataProvider } from '../config';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

const styles = theme => ({
  iframe: {
    marginBottom: theme.spacing.unit * 3,
    width: '100%',
    height: 'calc(100vh - 82px)',
  },
  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& > strong': {
      marginLeft: theme.spacing.unit / 2,
    }
  },
  chartTooltipColor: {
    width: 15,
    height: 15,
    marginRight: theme.spacing.unit,
  },
  creditData: {
    marginTop: theme.spacing.unit * 3,
    overflow: 'hidden',
  },
  creditContent: {
    padding: theme.spacing.unit * 2,
  },
  creditInfo: {
    paddingLeft: theme.spacing.unit * 3,
  },
  danger: {
    backgroundColor: red[200],
  },
  warning: {
    backgroundColor: amber[200],
  },
  safe: {
    backgroundColor: green[200],
  },
  chip: {
    marginRight: theme.spacing.unit * 2,
  },
  cell: {
    width: 200,
  },
  mtl: {
    marginTop: theme.spacing.unit * 5,
  },
});

const unitSort = (a, b) => {
  if (a.remaning < b.remaning) {
    return -1;
  }
  if (a.remaning > b.remaning) {
    return 1;
  }
  return 0;
};

const year = new Date().getFullYear();
const BI_URL = process.env.REACT_APP_BI_URL;
const QUERY_SALE = {
  filter: { createdAt: { gte: `${year}-01-01` } },
  pagination: {},
  sort: { field: 'createdAt', order: 'asc' }
};
const QUERY_UNIT = {
  pagination: {},
  sort: { field: 'createdAt', order: 'asc' }
};
const DATE = moment().startOf('isoWeek');

class Dashboard extends Component {
  state = {
    salesByDate: null,
    sales: [],
    units: [],
    date: 0,
    dates: [0, 1, 2].map(a => {
      const s = DATE.clone().subtract(a, 'w');
      let e;
      if (a) {
        e = s.clone().endOf('isoWeek');
      }
      return { s, e, l: s.format('DD/MM') };
    })
  };

  constructor(props) {
    super(props);

    this.chartOptions = {
      from: new Date(year, 0, 1).toISOString(),
      to: new Date(year, 11, 31).toISOString(),
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 20,
      },
      emptyColor: "#eeeeee",
      monthBorderColor: "#ffffff",
      dayBorderColor: "#ffffff",
      tooltip: ({ color, value, day }) => (
        <Typography component="div" className={props.classes.chartTooltip}>
          <div className={props.classes.chartTooltipColor} style={{backgroundColor: color}} />
          {day}: <strong>{value}</strong>
        </Typography>
      )
    };
  }

  componentDidMount() {
    const { showNotification } = this.props;
    dataProvider(GET_LIST, 'sales', QUERY_SALE)
      .then(({ data }) => {
        const sales = {};
        const salesByDate = [];
        data.forEach(sale => {
          const d = moment(sale.createdAt).format('YYYY-MM-DD');
          if (sales[d]) {
            sales[d] += 1;
          } else {
            sales[d] = 1;
          }
        });
        Object.keys(sales)
          .forEach(day => {
            salesByDate.push({ value: sales[day], day });
          });
        this.setState({ salesByDate, sales: data });
      })
      .catch(error => {
        showNotification(error.message, 'warning');
      });

    dataProvider(GET_LIST, 'units', QUERY_UNIT)
      .then(({ data }) => this.setState({ units: data }))
      .catch(error => {
        showNotification(error.message, 'warning');
      });
  }

  render() {
    const { classes } = this.props;
    const { salesByDate, units, sales, date, dates } = this.state;

    if (!salesByDate) {
      return null;
    }

    const currentDate = dates[date]

    const unitWithSales = units.filter(unit => {
      const unpaidSales = sales.filter(s => (
        s.salesmanId === unit.id
        && s.externalPayment
        && !s.externalPayment.billingId
        && (
          !currentDate
          || (
            !currentDate.s.isAfter(s.createdAt)
            && (!currentDate.e || !currentDate.e.isBefore(s.createdAt))
          )
        )
      ));
      if (!unpaidSales.length) {
        return false;
      }
      unit.unpaidSales = unpaidSales;
      unit.unpaid = 0;
      unpaidSales.forEach(({ externalPayment: { value } }) => {
        unit.unpaid += value;
      });
      unit.remaning = unit.credit - unit.unpaid / 100;
      return true;
    });

    return (
      <div>
        {BI_URL && (
          <iframe
            title="PowerBI"
            className={classes.iframe}
            src={BI_URL}
            frameBorder={0}
            allowFullScreen
          />
        )}

        <div className={classes.mtl} style={{height: 260}}>
          <ResponsiveCalendarCanvas
            {...this.chartOptions}
            data={salesByDate}
          />
        </div>
        <div className={classes.mtl}>
          {dates.map((d, i) => (
            <Chip
              key={i}
              className={classes.chip}
              label={d.l}
              onClick={() => this.setState({ date: i })}
              color={i === date ? 'primary' : 'default'}
            />
          ))}
          {unitWithSales
            .sort(unitSort)
            .map(({ credit, unpaid, remaning, unpaidSales, name, id }) => {
              const delta = unpaid/credit;
              const mode = !isNaN(delta) && delta > 80 ? 'danger' : (delta > 50 ? 'warning' : 'safe');
              return (
                <Paper key={id} className={classes.creditData}>
                  <div className={classNames(classes.creditContent, classes[mode])}>
                    <Typography variant="title">
                      <Link to={`/sales?filter=%7B"createdAt"%3A%7B"gte"%3A"${currentDate.s.format('YYYY-MM-DD')}"${currentDate.e ? `%2C"lte"%3A"${currentDate.e.format('YYYY-MM-DD')}"` : ''}%7D%2C"externalPaymentId"%3A%7B"notNull"%3Atrue%7D%2C"salesmanId"%3A${id}%7D&order=ASC&page=1&perPage=10&sort=createdAt`}>{name}</Link>
                      <span className={classes.creditInfo}>
                        <strong>R$ {remaning}</strong>
                        {credit && ` de R$ ${credit}`}
                      </span>
                    </Typography>
                  </div>
                  <Table>
                    <TableBody>
                      {unpaidSales.map(({ id, name, externalPayment: { value }, createdAt }) => (
                        <TableRow key={id}>
                          <TableCell>{name}</TableCell>
                          <TableCell className={classes.cell}>R$ {value / 100}</TableCell>
                          <TableCell className={classes.cell}>{moment(createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(connect(undefined, { showNotification })(Dashboard));