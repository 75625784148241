import React, { useState, useCallback } from 'react';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {
  Show,
  TabbedShowLayout,
  TextField,
  Tab,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  DateField,
  NumberField,
  SelectField,
  Datagrid,
  SingleFieldList,
  ChipField,
  EditButton,
  GET_LIST
} from 'react-admin';
import { PRICE_FORMAT, Roles } from '../../data';
import { ConditionalUser, Disabled } from '../conditionals';
import { dataProvider } from '../../config';
import XLSX from 'xlsx';

const cardActionStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 0
};
const snakeCase = string => string && typeof string === 'string' ? string.toLowerCase().replace(/\s/g, '_') : string;

const ExportButton = ({ record, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const exportXls = useCallback(async () => {
    setIsLoading(true);
    const { data } = await dataProvider(GET_LIST, 'exams', {
      filter: { unitId: record.id, include: 'disabled' },
      pagination: {},
      sort: { field: 'name', order: 'asc' }
    });
    const sheet = [{
      name: 'Nome',
      value: 'Preço',
      waitTime: 'Espera média (dias)',
      enabled: 'Desabilitado',
      disabledId: 'Removido',
      instructions: 'Instruções'
    }];
    data.forEach(({ name, value, waitTime, enabled, disabled, instructions }) => {
      sheet.push({
        name,
        value: { t: "n", v: value/100, z: "\"R$\" #0.00" },
        waitTime: { t: "n", v: waitTime },
        enabled: enabled ? '' : 'Sim',
        disabledId: disabled ? disabled.reason : '',
        instructions
      });
    });
    const ws = XLSX.utils.json_to_sheet(sheet, { skipHeader: true });
    ws['!rows'] = [{ hpx: 25 }];
    ws['!cols'] = [{ wch: 40 }];
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'exames');
    XLSX.writeFile(wb, `exames_nema_${snakeCase(record.name)}.xlsx`);
    setIsLoading(false);
  }, [record]);

  return <Button {...props} disabled={isLoading} onClick={exportXls} />;
};

const UnitShowActions = ({ basePath, data }) => (
  <CardActions style={cardActionStyle}>
    <ExportButton color="primary" record={data}>Exportar exames</ExportButton>
    <EditButton basePath={basePath} record={data} />
  </CardActions>
);

export default function UnitShow(props) {
  return (
    <Show {...props} actions={<UnitShowActions />}>
      <TabbedShowLayout>
        <Tab label="Informações">
          <TextField source="name" />
          <ReferenceField source="ownerId" reference="users">
            <TextField source="email" />
          </ReferenceField>
          <TextField source="cnpj" />
          <TextField source="crm" />
          <TextField source="responsible" />
          <TextField source="cnes" />
          <TextField source="socialName" />
          <ReferenceManyField label="Telefones" reference="phones" target="unitId">
            <SingleFieldList linkType={false}>
              <ChipField source="number" />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField reference="addresses" target="unitId" addLabel={false}>
            <Datagrid>
              <TextField source="cep" />
              <TextField source="street" />
              <TextField source="number" />
              <TextField source="complement" />
              <TextField source="neighborhood" />
              <TextField source="city" />
              <TextField source="state" />
            </Datagrid>
          </ReferenceManyField>
          <BooleanField source="active" />
          <DateField source="acceptedTerms" />
        </Tab>
        <Tab label="Financeiro">
          <ReferenceField source="paymentSellerId" reference="paymentSellers/list">
            <TextField source="businessName" />
          </ReferenceField>
          <TextField source="costAmount" />
          <TextField source="costPercent" />
          <TextField source="monthlyPayment" />
          <TextField source="credit" />
          <ConditionalUser source="commercialId" addLabel />
        </Tab>
        <Tab label="Acessos">
          <ReferenceManyField reference="accesses" target="unitId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="userId" reference="users" linkType="show">
                <TextField source="email" />
              </ReferenceField>
              <SelectField choices={Roles} source="role" />
              <BooleanField source="notify" />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Exames">
          <ReferenceManyField reference="exams" target="unitId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <NumberField source="value" options={PRICE_FORMAT} />
              <DateField source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Acordos">
          <ReferenceManyField reference="agreements" target="unitId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <ReferenceManyField filter={{disabledId: null}} label="Parceiros" reference="sellers" target="agreementId">
                <SingleFieldList linkType={false}>
                  <ReferenceField source="unitId" reference="units">
                    <ChipField source="name" />
                  </ReferenceField>
                </SingleFieldList>
              </ReferenceManyField>
              <DateField source="createdAt" />
              <DateField source="finishedAt" />
              <ConditionalUser source="finishedBy" />
              <Disabled label="Desabilitado em" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Parceiros">
          <ReferenceManyField reference="sellers" target="unitId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="agreementId" reference="agreements" linkType="show">
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" />
              <DateField source="confirmatedAt" />
              <ConditionalUser source="confirmatedBy" />
              <Disabled label="Desabilitado em" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}