import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  DisabledInput,
  TextInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  TextField,
  DateField,
  EditButton,
  NumberInput,
  SelectField,
  BooleanField,
  Toolbar,
  SaveButton
} from 'react-admin';
import AccessCreateModal from '../accesses/createModal';
import { Roles, filterLike } from '../../data';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default function UnitEdit(props) {
 return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Informações">
          <DisabledInput source="id" />
          <TextInput source="name" />
          <ReferenceInput
            source="ownerId"
            reference="users"
            perPage={10}
            filterToQuery={filterLike('email')}
          >
            <AutocompleteInput optionText="email" />
          </ReferenceInput>
          <TextInput source="cnpj" />
          <TextInput source="crm" />
          <TextInput source="responsible" />
          <TextInput source="cnes" />
          <TextInput source="socialName" />
          <BooleanInput source="active" />
        </FormTab>
        <FormTab label="Financeiro">
          <ReferenceInput
            source="paymentSellerId"
            reference="paymentSellers/list"
            perPage={10}
            filterToQuery={filterLike('businessName')}
          >
            <AutocompleteInput optionText="businessName" />
          </ReferenceInput>
          <NumberInput source="costAmount" />
          <NumberInput source="costPercent" />
          <NumberInput source="monthlyPayment" />
          <NumberInput source="credit" />
          <ReferenceInput
            source="commercialId"
            reference="users"
            perPage={10}
            filterToQuery={text=>({ email: { like: `%${text}%` } })}
          >
            <AutocompleteInput optionText="email" />
          </ReferenceInput>
        </FormTab>
        <FormTab label="Acessos">
          <ReferenceManyField reference="accesses" target="unitId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="userId" reference="users" linkType="show">
                <TextField source="email" />
              </ReferenceField>
              <SelectField source="role" choices={Roles} />
              <BooleanField source="notify" />
              <DateField source="createdAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <AccessCreateModal record={props.record} mode="unitId" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
 }
