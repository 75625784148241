import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField
} from 'react-admin';

export default function ProfileShow(props) {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="cellphone" />
        <TextField source="cpf" />
      </SimpleShowLayout>
    </Show>
  );
}