import React from 'react';
import {
  Edit,
  TabbedForm,
  TextInput,
  DisabledInput,
  FormTab,
  BooleanInput,
  ReferenceField,
  ReferenceManyField,
  DateField,
  TextField,
  Datagrid,
  EditButton,
  DeleteButton,
  BooleanField,
  SelectField,
  Toolbar,
  SaveButton
} from 'react-admin';
import AccessCreateModal from '../accesses/createModal';
import { Roles } from '../../data';

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export default function UserEdit(props) {
  const { record } = props;
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab label="Informações">
          <DisabledInput source="id" />
          <TextInput source="username" />
          <TextInput source="email" type="email" />
          <BooleanInput source="active" />
          <BooleanInput source="club" />
        </FormTab>
        <FormTab label="Acessos">
          <ReferenceManyField reference="accesses" target="userId" addLabel={false}>
            <Datagrid>
              <ReferenceField source="unitId" reference="units" linkType="show">
                <TextField source="name" />
              </ReferenceField>
              <SelectField source="role" choices={Roles} />
              <BooleanField source="notify" />
              <DateField source="createdAt" />
              <EditButton />
              <DeleteButton />
            </Datagrid>
          </ReferenceManyField>
          <AccessCreateModal record={record} mode="userId" />
        </FormTab>
        <FormTab label="Unidades">
          <ReferenceManyField reference="units" target="ownerId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <TextField source="cnpj" />
              <DateField source="createdAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Comercial">
          <ReferenceManyField reference="units" target="commercialId" addLabel={false}>
            <Datagrid>
              <TextField source="name" />
              <TextField source="cnpj" />
              <DateField source="createdAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
}