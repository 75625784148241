import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  DateField,
  EditButton
} from 'react-admin';

const ProcedureFilter = props => (
  <Filter {...props}>
    <TextInput label="Nome" source="[name][like]" alwaysOn />
    <TextInput label="Tuss" source="[tuss][like]" alwaysOn />
  </Filter>
);

export default function ProcedureList(props) {
  return (
    <List {...props} filters={<ProcedureFilter />}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="tuss" />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
}