import React from 'react';
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextInput
} from 'react-admin';
import { SaleStatuses } from '../../data';

export default function SaleEdit(props) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <SelectInput source="status" choices={SaleStatuses} />
        <TextInput source="paidNema" />
        <TextInput source="paidPractice" />
      </SimpleForm>
    </Edit>
  );
}